import React from 'react';
import { Grid } from '@material-ui/core'
import SingleDevice from './SingleDevice'
import TextField from '@material-ui/core/TextField'
import { argumentPlaceholder } from '@babel/types';
import { parse } from '@babel/core';

class DeviceMonitor extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            deviceObj: [],
            filter: "",
            filteredDevices: []
        }

        this.filterValue = this.filterValue.bind(this)
        this.getDeviceData = this.getDeviceData.bind(this)
        // this.buildMonitors = this.buildMonitors.bind(this)
    }
        
    componentDidMount(){
     //  this.interval = setInterval(this.componentDidMount, 10000)
        // return this.buildMonitors()

        this.getDeviceData()
        this.interval = setInterval(()=> this.getDeviceData(), 1000)
        
    }

    callgetDeviceData= ()=>{
        this.getDeviceData()
    }
    
    getDeviceData(){
        const url = 'https://seco-temp.cedarware.io/api/v1/device' 
        fetch(url,{
            method:'GET',
        })
        .then(response => response.json())
        .then(jsonData => {
            this.setState({
                deviceObj: jsonData,
                filteredDevices: jsonData
            })
        })
        .catch((error) => {
            console.error(error)
        })
    }

    filterValue = event => {
        this.setState({ filter: event.target.value })
    }

    render(){
        const {filter, filteredDevices} = this.state
        const filterDevices = filteredDevices.filter(device => {
            return device.unit_id.includes(filter) })

        return(
            <div>
                <br/>
                    <form noValidate autoComplete="off">
                        <TextField
                        id="standard-name"
                        label="Filter Devices"
                        value={this.state.txVal}
                        style={{ margin: 8 }}
                        onChange={ this.filterValue }
                        margin="normal"
                        InputLabelProps={{
                            shrink: true
                        }}
                        />
                    </form>

                <br/>
                <Grid container  justify='center' spacing={3} >
                 {  
                     filterDevices.map(device => {
                    let name = device.unit_id
                    let lastMessage = device.last_message

                    if(lastMessage){
                        if(!lastMessage.endsWith('Z')){
                            lastMessage += "Z"
                        }

                        lastMessage = new Date(lastMessage).toLocaleString()
                    }

                    let valState = false
                    let valStateSet = false
                    var val2State = false
                    var val2StateSet = false
                    var val3State = false
                    var val3StateSet = false
                    var val4State = false
                    var val4StateSet = false
                    
                    let setLoraRate = 15 
                    let argolisState = "v2"

                    let setActVal = 0

                    
                    if (device.hasOwnProperty('set_lora_rate')){
                        setLoraRate = device.set_lora_rate; 
                    }else if (device.hasOwnProperty('lora_rate')){
                        setLoraRate = device.lora_rate
                    }
                    if (device.hasOwnProperty('act_value_set')){
                        setActVal = device.act_value_set
                    }

                    var meters = new Array(16).fill(null)

                    for(var i = 1; i <= 16; i++){
                        if(device.hasOwnProperty('meter_' + i)){
                            meters[i - 1] = ~~parseInt(device['meter_' + i].substring(4), 16);
                            if(i == 16){
                                meters[i - 1] /= 10;
                            }else{
                                meters[i - 1] /= 100;
                            }
                        }
                    }

                    if (device.hasOwnProperty('valve_state')){
                        if (device.valve_state === "on"){
                            valState = true
                        } else {
                            valState = false
                        }
                    }
                    if (device.hasOwnProperty('valve_state_set')){
                        if (device.valve_state_set === "on"){
                            valStateSet = true
                        } else {
                            valStateSet = false
                        }
                    }
                    if (device.hasOwnProperty('argolis')){
                        argolisState = device.argolis;
                    }
                    if (argolisState === 'module'){
                        if (device.hasOwnProperty('valve_2_state')){
                            if (device.valve_2_state === "on"){
                                val2State = true
                            } else {
                                val2State = false
                            }
                        }
                        if (device.hasOwnProperty('valve_2_state_set')){
                            if (device.valve_2_state_set === "on"){
                                val2StateSet = true
                            } else {
                                val2StateSet = false
                            }
                        }
                        if (device.hasOwnProperty('valve_3_state')){
                            if (device.valve_3_state === "on"){
                                val3State = true
                            } else {
                                val3State = false
                            }
                        }
                        if (device.hasOwnProperty('valve_3_state_set')){
                            if (device.valve_3_state_set === "on"){
                                val3StateSet = true
                            } else {
                                val3StateSet = false
                            }
                        }
                        if (device.hasOwnProperty('valve_4_state')){
                            if (device.valve_4_state === "on"){
                                val4State = true
                            } else {
                                val4State = false
                            }
                        }
                        if (device.hasOwnProperty('valve_4_state_set')){
                            if (device.valve_4_state_set === "on"){
                                val4StateSet = true
                            } else {
                                val4StateSet = false
                            }
                        }
                    }

                    let resetState = false;
                    if(device.reset_next_wake === 'true'){
                        resetState = true;
                    }

                    let versionsState = false;
                    if(device.hasOwnProperty('read_versions') && device.read_versions !== 'none' ){
                        versionsState = true;
                    }

                    return (<SingleDevice myDevice={device} deviceName = {name} lastMessagefromDevice = {lastMessage}
                        setLoraRate = {setLoraRate} meters = {meters} valveState = {valState} valveStateSet = {valStateSet}
                        valve2State ={val2State} valve2StateSet = {val2StateSet} valve3State = {val3State} valve3StateSet = {val3StateSet}
                        valve4State = {val4State} valve4StateSet = {val4StateSet} callgetDeviceData = {this.callgetDeviceData}
                        argolisState = {argolisState} getDeviceIDcb2 = {this.props.getDeviceIDcb} key={name}
                        resetState = {resetState}
                        versionsState = {versionsState}
                        setActVal = {setActVal}
                    />)
                    })
                }
                </Grid>

            </div>
        )
    }
}
export default DeviceMonitor
