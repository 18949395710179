import React from 'react';
import './App.css';
import NavBar from './components/Navigation.js'
import DeviceMonitor from './components/DeviceMonitor_v2.js'
import { Router } from '@reach/router';
import SingleDeviceLog from './components/SingleDeviceLog';


class App extends React.Component {
  constructor(props){
    super(props)
    this.state = { id: ""}
    this.getDeviceID = this.getDeviceID.bind(this)
  }
  getDeviceID(singleID){
    this.setState({
      id: singleID
    })
    
    console.log(1)
  }

  render(){
  return ( 
    <div className="App">
      <NavBar>
    
      </NavBar>
      <Router>
          <DeviceMonitor path="/" getDeviceIDcb = {this.getDeviceID} />
          <SingleDeviceLog path=":name" name={this.state.id}/>
        </Router>
    </div>
  );
  }
}

export default App;
