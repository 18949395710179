import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'  
import { Typography } from '@material-ui/core'
import '../css/SingleDeviceLog.css'

function hex2a(hexx) {
    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

const abortController = new AbortController()
class SingleDeviceLog extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            deviceName: this.props.name,
            deviceLog: [],
            rows: [],
            devicehasMtr1: this.props.hasMtr1
        }
        
        this.buildRow = this.buildRow.bind(this)
        this.buildTableValues = this.buildTableValues.bind(this)
        this.getDeviceLog = this.getDeviceLog.bind(this)


    }

    componentDidMount(){
        this.getDeviceLog()
        this.interval = setInterval(this.getDeviceLog, 5000)
    }

    getDeviceLog(){
        let url = 'https://seco-temp.cedarware.io/api/v1/event?unit_id='
        fetch(url + this.props.name, {signal: abortController.signal })
         .then(response => response.json())
         .then(jsonData => {
            this.buildTableValues(jsonData)
         }).catch(error => {
             console.error(error)
         })
        
    }

    buildRow( time, port, direction, data, command, param1, param2, isOne){
        var hasMeter1 = this.state.devicehasMtr1

        if(time){
            if(!time.endsWith('Z')){
                time += "Z"
            }

            time = new Date(time).toLocaleString()
        }

        return {time, port, direction, data, command, param1, param2, isOne, hasMeter1}
    }

    buildTableValues(jsonData){
        let tableRows = []
        
        for ( let i = 0; i < jsonData.length; i++ ){
            let command = parseInt(jsonData[i].data.substr(0,2), 16)
            command = String.fromCharCode(command)
            
            let param1String = jsonData[i].data.substr(2,2)
            let param1 =( param1String + '/' + hex2a(param1String) + '/' + parseInt(param1String,16))
            
            let param2String = jsonData[i].data.substr(4)
            let param2 =( param2String + '/' + hex2a(param2String) + '/' + parseInt(param2String,16))
            let isOne = (param1String === 1) ? true : false
            
            tableRows.push(this.buildRow(jsonData[i].posted_time, jsonData[i].port, jsonData[i].direction,
                 jsonData[i].data, command, param1, param2, isOne))
        }

        this.setState({
            deviceLog: jsonData,
            rows: tableRows
        })
    }

    componentWillUnmount(){
    //    this.abortController.abort()
        console.log("Unmounting")
    }

    render(){
        return(
            <div>
            <Paper>
                <br/>
                <Typography variant="h2">  Event Log: {this.props.name}</Typography>
                <br/>    
                    <Table>
                        <TableHead>
                            <TableRow selected={true}>
                                <TableCell align="center">Time</TableCell>
                                <TableCell align="center">Port</TableCell>
                                <TableCell align="center">Direction</TableCell>
                                <TableCell align="center">Data</TableCell>
                                <TableCell align="center">Command</TableCell>
                                <TableCell align="center">Param1</TableCell>
                                <TableCell align="center">Param2</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.rows.map(row =>(
                                <TableRow key={row.time + row.direction} style={row.direction === 'inbound' ? {background:'#00DAB4'}:{background:'#00AFD7'}}
                                className={row.command === 'm' ? row.isOne ? 'TableRowNewSession' : row.hasMeter1 ? '' : 'TableRowNewSession' : ''}>
                                    <TableCell align="center" component="th" scope="row">{row.time}</TableCell>
                                    <TableCell align="center">{row.port}</TableCell>
                                    <TableCell align="center">{row.direction}</TableCell>
                                    <TableCell align="center">{row.data}</TableCell>
                                    <TableCell align="center" >{row.command}</TableCell>
                                    <TableCell align="center">{row.param1}</TableCell>
                                    <TableCell align="center">{row.param2}</TableCell>
                                </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

export default SingleDeviceLog

//                                    <TableCell align="center">{row.direction}</TableCell>
//                                    { row.command === 'm' ? <TableCell align="center"><u className='CommandTag'>{row.command}</u></TableCell> :<TableCell align="center">{row.command}</TableCell> }
//                                 className={row.isOne ? row.command === 'm' ? 'TableRowNewSession' : "" : ""} >
