import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
import { Button } from '@material-ui/core';
import { Link } from "@reach/router"


export default function NavigationBar(){

    return(
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Button variant='contained' color="inherit"><Link to="/">Device</Link></Button>
                </Toolbar>
            </AppBar>
        </div>
    )
}

/* 
                    <Button>
                    <img src={logo} alt="Subeca" height="48" width="48" onClick={gotoHomePage} className={classes.button}/>
                    </Button>
                    <Button className={classes.button} color="inherit" size="large" onClick={gotoDevicesPage}>
                        Devices
                    </Button>
*/