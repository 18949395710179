import React from 'react';
import { Button, Grid, Paper, Typography, ButtonGroup, FormControlLabel, Select, MenuItem, InputLabel, FormControl, FormGroup, Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import { Link } from "@reach/router"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

/**
 * valve2State ={val2State} valve2StateSet = {val2StateSet} valve3State = {val3State} valve3StateSet = {val3StateSet}
                        valve4State = {val4State} valve4StateSet = {val4StateSet}
 */
class SingleDevice extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            //deviceName = {name} lastMessagefromDevice = {lastMessage} meter1 = {mtr1} meter3 = {mtr3} 
            name: this.props.deviceName,
            lastMessage: this.props.lastMessagefromDevice,
            valveStatus: this.props.valveState,         // Display LED
            valveStateSet: this.props.valveStateSet,    // Toggle Valve
            valve2Status: this.props.valve2State,
            valve2StateSet: this.props.valve2StateSet,
            valve3Status: this.props.valve3State,
            valve3StateSet: this.props.valve3StateSet,
            valve4Status: this.props.valve4State,
            valve4StateSet: this.props.valve4StateSet,
            meters : this.props.meters,
            txVal: this.props.setLoraRate,
            argolisState : this.props.argolisState,
            actVal: this.props.setActVal,
        }
    }

    handleChange = event => { // Tx Change function
        var setLoraRate ={}
        if (event.target.value > 0){
            this.setState({
                txVal: event.target.value
            })
            setLoraRate['set_lora_rate'] = event.target.value
        } else if (event.target.value > 60){
            this.setState({
                txVal: 60
            })
        } else {
            this.setState({
                txVal: 1
            })
            setLoraRate['set_lora_rate'] = 1
        }
        console.log(setLoraRate)
 //       const proxyurl = 'https://cors-anywhere.herokuapp.com/'
        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        fetch(url + this.state.name, {
            method: 'POST',
            body: JSON.stringify(setLoraRate),
            headers:{
                'Content-Type' : 'application/json',
                'Accept': 'application/json'
            }
        })
        .then (response => response.json())
        .then (jsonData =>{
            console.log(jsonData)
        })
    }

    handleActChange = event => {
        var value = 0;
        if(event.target.value < 0){
            value = 0;
        }else if(event.target.value > 100){
            value = 100;
        }else{
            value = event.target.value;
        }

        this.setState({
            actVal: value,
        })

        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        fetch(url + this.state.name, {
            method: 'POST',
            body: JSON.stringify({
                'act_value_set': value
            }),
            headers:{
                'Content-Type' : 'application/json',
                'Accept': 'application/json'
            }
        })
    }

    toggleArgolis = event => {
        console.log(event.target.value)
        var currentState = event.target.value
        var sentArgolisState = {}
        sentArgolisState['argolis'] = event.target.value
            //    const proxyurl = 'https://cors-anywhere.herokuapp.com/'

        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        fetch(url + this.state.name,{
            method: "POST",
            body: JSON.stringify(sentArgolisState),
            headers:{
                'Content-Type' : 'application/json',
                'Accept': 'application/json'   
            }
        })
        .then(response => response.json())
        .then(jsonData =>{
            console.log(jsonData)
        })
        .catch(error => {
            console.log(error)
        })
        this.setState({
            argolisState: currentState
        })
    }


    // 500 Internal Error will Resolve later | Tried using fetch & POST but did not work and XMLHttpRequest
    toggleValve(stateofValve,deviceID){
    //    const proxyurl = 'https://cors-anywhere.herokuapp.com/'
        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        var val = this.state.valveStateSet
        var valveState = {}
        valveState['valve_state_set'] = (!val) ? "on" : "off"
        fetch(url + this.state.name,{
            method: "POST",
            body: JSON.stringify(valveState),
            headers:{
                'Content-Type' : 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(jsonData =>{
            console.log(jsonData)
        })
        .catch(error => {
            console.log(error)
        })
        this.setState({
            valveStateSet: !val
        })

        this.props.callgetDeviceData()
    }

    toggleValve2(){
        console.log("TV2")
        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        var val = this.state.valve2StateSet
        console.log("val2: " + val)
        console.log(this.state.name)
        var valveState = {}
        valveState['valve_2_state_set'] = (!val) ? "on" : "off"
        fetch(url + this.state.name,{
            method: "POST",
            body: JSON.stringify(valveState),
            headers:{
                'Content-Type' : 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(jsonData =>{
            console.log(jsonData)
        })
        .catch(error => {
            console.log(error)
        })
        this.setState({
            valve2StateSet: !val
        })
        console.log("val2: " + !val)
        this.props.callgetDeviceData()

    }

    toggleValve3(){
        console.log("TV3")
        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        var val = this.state.valve3StateSet
        var valveState = {}
        valveState['valve_3_state_set'] = (!val) ? "on" : "off"
        fetch(url + this.state.name,{
            method: "POST",
            body: JSON.stringify(valveState),
            headers:{
                'Content-Type' : 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(jsonData =>{
            console.log(jsonData)
        })
        .catch(error => {
            console.log(error)
        })
        this.setState({
            valve3StateSet: !val
        })
        this.props.callgetDeviceData()

    }

    toggleValve4(){
        console.log("TV4")
        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        var val = this.state.valve4StateSet
        var valveState = {}
        valveState['valve_4_state_set'] = (!val) ? "on" : "off"
        fetch(url + this.state.name,{
            method: "POST",
            body: JSON.stringify(valveState),
            headers:{
                'Content-Type' : 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(jsonData =>{
            console.log(jsonData)
        })
        .catch(error => {
            console.log(error)
        })
        this.setState({
            valve4StateSet: !val
        })
        this.props.callgetDeviceData()

    }

    deleteDevice = () => {
        console.log("deleteDevice")
        const url = 'https://seco-temp.cedarware.io/api/v1/device/'
        fetch(url + this.state.name,{
            method: "DELETE",
        })
        .then(response => response.json())
        .then(jsonData =>{
            console.log(jsonData)
        })
        console.log("end delete device")

    }

    render(){
        return (
            <React.Fragment>
                    <Grid item sm={"auto"} >
                    <Paper>
                        <Typography variant='h6'>ID: {this.state.name}</Typography>
                        <Typography variant='body2'>Last Update: {this.props.lastMessagefromDevice}</Typography>
                        {
                            this.props.meters.map((value, index) => {
                                if(value){
                                    return <Typography>{index != 15 ? "Meter" : "Pressure"} {index + 1}: {value}</Typography>
                                }else{
                                    return null
                                }
                            })
                        }
                        <form noValidate autoComplete="off">
                            <TextField
                            id="standard-number"
                            label="Tx Rate"
                            value={this.state.txVal}
                            type="number"
                            onChange={this.handleChange }
                            InputLabelProps={{
                                shrink: true
                            }}
                            margin="normal"

                        /> 
                        </form>

                        <br/>
                        {
                            this.state.argolisState != 'module' ? 
                            this.props.valveState === this.props.valveStateSet ?
                        <FormControlLabel
                            control={
                                <Switch
                                color='default'
                                checked={this.props.valveStateSet}
                                style={{color: '#0baf75'}}
                                 onChange={ () => {this.toggleValve( this.state.name)}}
                                 />
                            } 
                            label="Toggle Valve 1"
                        /> : 
                        <FormControlLabel
                            control={
                                <Switch
                                checked={this.props.valveStateSet}
                                color="default"
                                style={{color:'#fa78ca'}}
                                 onChange={ () => {this.toggleValve( this.state.name)}}
                                 />
                            } 
                            label="Toggle Valve 1"
                        /> :
                        <Grid item>
                        <Grid item justify='center'>
                        <FormGroup row>
                            {
                                this.props.valveState === this.props.valveStateSet ?
                        <FormControlLabel
                            control={
                                <Switch
                                color='default'
                                style={{color: '#0baf75'}}
                                checked={this.props.valveStateSet}
                                 onChange={ () => {this.toggleValve( this.state.name)}}
                                 />
                            } 
                            label="Toggle V1"
                        /> : 
                        <FormControlLabel
                            control={
                                <Switch
                                color="default"
                                style={{color:'#fa78ca'}}
                                checked={this.props.valveStateSet}
                                 onChange={ () => {this.toggleValve( this.state.name)}}
                                 />
                            } 
                            label="Toggle V1"
                        />    
                            }
                            {
                                this.props.valve3State === this.props.valve3StateSet ?
                        <FormControlLabel
                            control={
                                <Switch
                                color='default'
                                style={{color: '#0baf75'}}
                                checked={this.props.valve3StateSet}
                                onChange={() =>this.toggleValve3(this.state.name)}
                                />
                            }
                            label="Toggle V3"
                            /> :
                            <FormControlLabel
                            control={
                                <Switch
                                color="default"
                                style={{color:'#fa78ca'}}
                                checked={this.props.valve3StateSet}
                                onChange={() =>this.toggleValve3(this.state.name)}
                                />
                            }
                            label="Toggle V3"
                            /> 
                            }
                        </FormGroup>
                        </Grid>
                        <Grid item justify='center'>
                        <FormGroup row>
                            {
                                this.props.valve2State === this.props.valve2StateSet ?
                        <FormControlLabel
                            control={
                                <Switch
                                color='default'
                                style={{color: '#0baf75'}}
                                checked={this.props.valve2StateSet}
                                onChange={() =>this.toggleValve2(this.state.name)}
                                />
                            }
                            label="Toggle V2"
                        /> :
                        <FormControlLabel
                            control={
                                <Switch
                                color='default'
                                style={{color:'#fa78ca'}}
                                checked={this.props.valve2StateSet}
                                onChange={() =>this.toggleValve2(this.state.name)}
                                />
                            }
                            label="Toggle V2"
                        />    
                        }
                        {
                            this.props.valve4State === this.props.valve4StateSet ?
                        <FormControlLabel
                            control={
                                <Switch
                                color='default'
                                style={{color: '#0baf75'}}
                                checked={this.props.valve4StateSet}
                                onChange={() =>this.toggleValve4(this.state.name)}
                                />
                            }
                            label="Toggle V4"
                        /> : 
                        <FormControlLabel
                            control={
                                <Switch
                                color='default'
                                style={{color:'#fa78ca'}}
                                checked={this.props.valve4StateSet}
                                onChange={() =>this.toggleValve4(this.state.name)}
                                />
                            }
                            label="Toggle V4"
                        />
                        }
                        </FormGroup> 
                        </Grid>
                        </Grid>
                        }

                        {this.state.argolisState == 'module' ? 
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="standard-number"
                                    label="ACT Value"
                                    value={this.state.actVal}
                                    type="number"
                                    onChange={this.handleActChange }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                /> 
                            </form>
                        :
                            ''
                        }

                        <form>
                            <FormControl>
                                <Select
                                    value={this.state.argolisState}
                                    onChange={(event) => this.toggleArgolis(event)}
                                    inputProps={{
                                        name: 'argolis',
                                        id: 'argolis-state',
                                    }}
                                >
                                    {
                                        this.state.argolisState != 'v2' && this.state.argolisState != 'module' ?
                                            <MenuItem value={this.state.argolisState}>{this.state.argolisState}</MenuItem>
                                        :
                                            ""
                                    }
                                    <MenuItem value={'v2'}>v2</MenuItem>
                                    <MenuItem value={'module'}>module</MenuItem>
                                </Select>
                            </FormControl>
                        </form>

                        <ButtonGroup>
                        <Button onClick={()=> this.props.getDeviceIDcb2(this.state.name, this.state.mtr1)} ><Link to={this.state.name} state={{deviceID: this.state.name, mtr1: this.state.mtr1}}>Log</Link></Button>
                            <Button disabled={this.props.versionsState} onClick={
                                () => {
                                    const url = 'https://seco-temp.cedarware.io/api/v1/device/'
                                    fetch(url + this.state.name, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            read_versions:"fw",
                                        }),
                                        headers:{
                                            'Content-Type' : 'application/json',
                                            'Accept': 'application/json'
                                        }
                                    })
                                }
                            }>
                                {this.props.versionsState ? "Versions Pending" : "Read Versions"}
                            </Button>
                            <Button disabled={this.props.resetState} onClick={
                                () => {
                                    const url = 'https://seco-temp.cedarware.io/api/v1/device/'
                                    fetch(url + this.state.name, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            reset_next_wake:"true",
                                        }),
                                        headers:{
                                            'Content-Type' : 'application/json',
                                            'Accept': 'application/json'
                                        }
                                    })
                                }
                            }>  
                                {this.props.resetState ? "Resetting" : "Reset"}
                            </Button>
                            <Button variant='outlined' onClick={()=>this.deleteDevice()}>
                            <DeleteOutlineIcon/>
                        </Button>
                        </ButtonGroup>
                    </Paper>
                    </Grid>
            </React.Fragment>
        )
    }    
}

export default SingleDevice
